/*body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.logo {
}*/

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 767px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background: linear-gradient(
      to bottom,
      white 0%,
      white 40%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 0px;
    /* height: calc(100vh - 60px);*/
    height: 300px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 165px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    background-color: white;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 20px;
  }
  .nav-elements ul li:last-child {
    margin-right: unset;
    margin-top: 8px;
  }
  .nav-elements ul li:first-child {
    margin-right: unset;
    margin-top: 8px;
  }
}
